<template>
  <div class="container-fluid mt-3">
    <div class="d-flex flex-wrap mb-1 align-items-center justify-content-between">
      <Breadcrumb current_page="الصفحات"/>
      <router-link class=" el-button el-button--success el-button--default btn-add" to="/add-page">
        إضافة صفحة جديدة
      </router-link>
    </div>

    <el-card class="mt-2">
      <el-table id="top-table" :data="pagesList" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">

        <el-table-column prop="id" class-name="px-3" label="#" width="80"/>
        <el-table-column prop="name" label="الاسم" width="130"/>
        <el-table-column label="الأدوات" width="120" class-name="justify-content-start control-btns">
          <template #default="scope">

            <el-tooltip class="item" effect="dark" content="تعديل الصفحة" placement="top">
              <router-link :to="`/edit-page/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </router-link>
            </el-tooltip>
            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmDeactivate(scope.row.id)"
                title="هل أنت متأكد أنك تريد تعطيل هذه الصفحة؟">
              <template #reference>
                <el-button title="تعطيل الصفحة" class="py-1 px-2 btn btn-outline-warning">
                  <i class="las la-ban" style="font-size: 1.35em"/>
                </el-button>
              </template>
            </el-popconfirm>


          </template>
        </el-table-column>
      </el-table>
    </el-card>


  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';

import Breadcrumb from "@/components/Breadcrumb";
import {getAllPages} from "@/services/pages";

const pagesList = ref([])
const loading   = ref(true)
const router    = useRouter();

const fetchPages = async () => {
  const result         = await getAllPages()
  const {status, data} = result;
  if (status === 200)
  {
    pagesList.value = data
  }
  loading.value = false
}

onMounted(() => fetchPages())



const confirmDeactivate = id => {

}
</script>


<style scoped>
.container-fluid :deep(.el-table__empty-block)  {
  width: 100% !important;
}
</style>
